.badge {
    color: white;
    padding: 4px 8px;
    border-radius: 4px;
    display: inline-block;
    margin-left: 12px;

    &--ok {
        background-color: green;
    }

    &--error {
        background-color: red;
    }
}