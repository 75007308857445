.training {
    border: 1px solid lightgray;
    border-radius: 8px;
    box-shadow: 0 8px 16px 0 rgba(0,15,30,0.08);
    display: flex;
    flex-direction: column;
    overflow: hidden;

    &__head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: darkgray;
        padding: 12px;
    }

    &__date {
        font-size: 1em;
        font-weight: 500;
        padding: 4px 0;
    }

    &__booked-badge {
        color: white;
        background-color: green;
        padding: 4px 8px;
        border-radius: 4px;
        display: inline-block;
        margin-left: 12px;
    }

    &__participants {
        padding: 8px 12px 12px;
    }

    &__participants-list {
        margin: 6px 0 0;
    }

    &__actions {
        display: flex;
        align-items: stretch;
    }

    &__button {
        display: block;
        width: 100%;
        border: none;
        border-top: 1px solid lightgrey;
        padding: 10px;

        &:nth-child(2) {
            border-left: 1px solid lightgrey;
        }
    }

}
