.layout-wrapper {

    &__head {
        padding: 12px 12px;
        background-color: #bada55;
        box-shadow: 0 0 16px 0 rgba(0,15,30,0.12);
    }

    &__title {
        font-size: 1.5em;
    }

    &__content {
        padding: 6px 12px;
    }
}
