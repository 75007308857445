.trainings {
    &__week {
        margin-top: 40px;
        margin-bottom: 10px;
        border-bottom: 2px solid green;
    }

    &__list {
        padding: 0;
        margin: 0;
    }

    &__item {
        padding-top: 6px;
        list-style: none;
    }
}
